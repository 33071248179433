import Vue from 'vue'
import { SuperClusterAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer'
import Denque from 'denque'
import dayjs from 'dayjs'
import { locationService } from '@/services'
import {GmapRecord, GmapMarker} from '@/types/gmapMixinType'
import {GeoLatLng, StackGeoLocation} from '@/types/LocationServiceType'
import { GmapRecordSetting, StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { EventBus, ON_SHOW_MAP } from '@/eventbus'
import debounce from 'lodash/debounce'
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier'

declare global {
    interface Window {
        multiAPIMapQueue: Denque<GmapRecord & { attempts: number}>;
    }
}


const google = window.google
/**
   * A customized popup on the map.
   */
class Popup extends google.maps.OverlayView {
    position: google.maps.LatLng;
    containerDiv: HTMLDivElement;
    visible:boolean

    constructor(position: google.maps.LatLng, content: HTMLElement) {
        super()
        this.position = position
        this.visible = true

        content.classList.add('popup-bubble')

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement('div')

        bubbleAnchor.classList.add('popup-bubble-anchor')
        bubbleAnchor.appendChild(content)

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement('div')
        this.containerDiv.classList.add('popup-container')
        this.containerDiv.appendChild(bubbleAnchor)

        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv)
    }

    /** Called when the popup is added to the map. */
    onAdd() {
        this.visible = true
        this.getPanes()?.floatPane.appendChild(this.containerDiv)
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
        this.visible = false
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv)
        }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const divPosition = this.getProjection().fromLatLngToDivPixel(
            this.position
        )!

        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? 'block'
                : 'none'

        if (display === 'block') {
            this.containerDiv.style.left = divPosition.x + 'px'
            this.containerDiv.style.top = divPosition.y + 'px'
        }

        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display
        }
    }

    getDraggable() {
        return
    }

    getPosition() {
        return this.position
    }

    getVisible() {
        return this.visible
    }
}

type GoogleShapes = (google.maps.Marker|null|google.maps.Polygon|
    google.maps.Polyline|google.maps.Rectangle|google.maps.Circle)

export default Vue.extend({
    props: {
        markers: {
            default: () => [] as GmapMarker[],
        },
        gmapRecords: {
            default: () => [] as GmapRecord[]
        },
        gmapIconSetting: {
            default: () => ({}) as StackGmapIconSetting
        },
        gmapRecordSetting: {
            default: () => ({}) as GmapRecordSetting
        }
    },
    created() {
        this.debouncedPlotRecords = debounce(this.plotRecords, 500).bind(this)
    },
    async mounted() {
        if(this.initializeOnMount) {
            const mapElem = this.$refs.map as HTMLElement
            await this.initialize(mapElem)
            if(this.prepopulateOnInit){
                await this.debouncedPlotRecords()
            }
        }
        
        EventBus.$on(ON_SHOW_MAP, () => {
            
            setTimeout( ()=> {
                this.map?.fitBounds(this.bounds)
                this.map?.panToBounds(this.bounds)
            }, 500)
            
        })
        this.mapLoading = false
    },
    destroyed() {
        EventBus.$off()
    },
    data: () => ({
        isGroupped:true,
        clusterOn: true,
        shapes: [] as GoogleShapes[],
        radiusInfoBubble: null as Popup | null,
        map: null as google.maps.Map | null,
        bounds: new google.maps.LatLngBounds(),
        markerClusterer: null as MarkerClusterer | null,
        geocoder: new google.maps.Geocoder(),
        geoCodeCount: 0,
        delay: 125,
        debugGoogleMapsApi: false,
        integrateInfoWindows: true as boolean,
        openInfoWindowsOnPin: false as boolean,
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: { lat:-34.397,lng:150.644 },
        enableDrawingManager: true as boolean,
        panMapOnPin: true as boolean,
        initializeOnMount: true as boolean,
        mapLoading: true,
        prepopulateOnInit: true,
        debouncedPlotRecords: () => null as any,
        oms: null as any
    }),
    watch: {
        clusterOn(newValue) {
            if(this.markerClusterer) {
                // @ts-ignore
                const availableMarkers = [...this.markerClusterer.markers]
                this.markerClusterer?.clearMarkers()
                this.markerClusterer?.onRemove()
                this.markerClusterer?.setMap(null)

                this.markerClusterer = new MarkerClusterer(
                    {
                        map: this.map,
                        algorithm: new SuperClusterAlgorithm({maxZoom: newValue ? 19 : 0}),
                        markers: availableMarkers
                    }
                )

            }
        },
        async gmapRecords() {
            await this.debouncedPlotRecords()
        }
    },
    methods: {
        async initialize(mapElem: HTMLElement) {
            this.map = new google.maps.Map(mapElem,{
                zoom: this.zoom,
                center: this.center,
                mapTypeId: this.mapTypeId
            })
            this.radiusInfoBubble = this.createRadiusInfoBubble()

            this.oms = new OverlappingMarkerSpiderfier(this.map, {
                markersWontMove: true,
                markersWontHide: true
            })
    
            if(window.multiAPIMapQueue) {
                window.multiAPIMapQueue.clear()
            } else {
                window.multiAPIMapQueue = new Denque()
            }

            if(this.enableDrawingManager) {
                this.createDrawingManager()
            }
        },
        async plotRecords(){        
            this.mapLoading = true    
            this.clearMarkers()
            this.deleteAllShapes()
            this.bounds = new google.maps.LatLngBounds() 
            const gmapLocations = await locationService.getLocations(this.gmapRecords.filter(mr => this.isAddressGeocodable(mr.address)).map(mr => mr.address))
            const toBeCreatedMarkers:google.maps.Marker[] = []

            this.gmapRecords.forEach(mapRecord => {
                const mapRecordLocation = gmapLocations.find(gmapLocation => gmapLocation.address.toUpperCase() === mapRecord.address.toUpperCase())
                if(mapRecordLocation) {
                    if (this.shouldGeocodeLocation(mapRecordLocation.status)) {
                        // TODO
                        // A Bug: When all locations are not yet geocoded, the excluded item would not be rendered in the map, that is why it is safer to duplicate the geo request api for that address
                        // if (!window.multiAPIMapQueue.toArray().find(queuedItem => queuedItem.address === mapRecord.address)) {
                        //     window.multiAPIMapQueue.push(mapRecord)
                        // }
                        window.multiAPIMapQueue.push({...mapRecord, attempts: 0})
                    } else {
                        const geoCodedRecord = {...mapRecord, ...mapRecordLocation}
                        if(geoCodedRecord.status == 'OK') {
                            const geolocation = this.convertoLatLng(geoCodedRecord)

                            if(this.enableDrawingManager) {
                                this.markers.push({
                                    id: geoCodedRecord.id,
                                    location: geolocation,
                                    isSelected: false
                                })
                            }
                            if(this.panMapOnPin) {
                                this.bounds.extend(geolocation)
                            }
                            toBeCreatedMarkers.push(this.createMarker(geoCodedRecord))
                        }
                    }
                }
            })

            
            this.markerClusterer = new MarkerClusterer(
                {
                    map: this.map,
                    algorithm: new SuperClusterAlgorithm({maxZoom: this.clusterOn ? 19 : 0}),
                    markers: toBeCreatedMarkers
                }
            )
            if (!this.isElementHidden(this.$refs.map as HTMLElement)) {
                this.map?.fitBounds(this.bounds)
                this.map?.panToBounds(this.bounds)
            }
            this.geocodeUnknownAddress()
            this.mapLoading = false
        },
        hideRadiusInfoBubble(){
            this.radiusInfoBubble?.setMap(null)
        },
        deleteSelectedShape(shape: GoogleShapes) {
            if (!shape) return

            shape.setMap(null)
            this.markers.map(el => el.isSelected = false)
            this.hideRadiusInfoBubble()
        },
        deleteAllShapes() {
            this.shapes.forEach((shape)=> shape?.setMap(null))
            this.markers.map(el => el.isSelected = false)
            this.shapes = []
            this.hideRadiusInfoBubble()
        },
        createCustomButton({
            iconClass='',
            title='',
            onClick=()=>{
                return
            },
            toggle=[] as string[]
        }) {
            const button = document.createElement('button')
            button.classList.add('custom-button')

            button.title = title
            button.type = 'button'

            const icon = document.createElement('i')
            icon.classList.add('mdi')
            icon.classList.add(iconClass)

            button.appendChild(icon)

            if (toggle.length > 0) button.addEventListener('click', ()=>{
                if(this.isGroupped) {
                    this.isGroupped = false
                    icon.classList.remove(toggle[0])
                    icon.classList.add(toggle[1])
                } else {
                    this.isGroupped = true
                    icon.classList.remove(toggle[1])
                    icon.classList.add(toggle[0])
                }
            })
            button.addEventListener('click', onClick.bind(this))
            button.addEventListener('contextmenu', (e) => e.preventDefault())

            return button
        },
        createCustomButtonGroup(){
            const mainDiv = document.createElement('div')
            mainDiv.style.margin = '5px'
            mainDiv.style.zIndex = '10'
            mainDiv.style.marginLeft = '-5px'

            const mapMarker = ['mdi-map-marker-off','mdi-map-marker']
            mainDiv.appendChild(this.createCustomButton({
                iconClass:mapMarker[this.clusterOn ? 1 : 0],
                title:'Toggle markers',
                onClick:()=> {
                    this.clusterOn = !this.clusterOn
                },
                toggle: ['mdi-map-marker','mdi-map-marker-off']
            }))
            mainDiv.appendChild(this.createCustomButton({
                iconClass:'mdi-trash-can-outline',
                title:'Remove all shapes',
                onClick: this.deleteAllShapes
            }))

            this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(mainDiv)
        },
        createRadiusInfoBubble() {
            const el = document.createElement('div')
            const popUp = new Popup(this.map?.getCenter() as any,el)
            return popUp
        },
        centerRadiusInfoBubble(circle: google.maps.Circle){
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.radiusInfoBubble!.position = circle.getCenter()!
        },
        showRadiusInfoBubble(circle: google.maps.Circle) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.radiusInfoBubble!.position = circle.getCenter()!
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const firstChild = this.radiusInfoBubble!.containerDiv!.firstChild!.firstChild! as any
            firstChild.innerText = `Radius: ${(circle.getRadius() / 1000).toFixed(1)} km`
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.radiusInfoBubble!.setMap(this.map)
        },
        addShapeListeners(shape:GoogleShapes){
            const addListener = google.maps.event.addListener
            shape?.addListener('rightclick', () =>{
                this.deleteSelectedShape(shape)
            })

            shape?.addListener('dragend', () => {
                this.getSelectedMarkers(shape)
            })

            if (shape instanceof google.maps.Polygon) {
                // remove vertex
                shape?.addListener('click', (e: google.maps.PolyMouseEvent)=> {
                    if (e.vertex === undefined) return

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const path = shape.getPath()!
                    path.removeAt(e.vertex)
                    if (path.getLength() < 3) shape.setMap(null)
                })

                addListener(shape.getPath(), 'set_at', () => {
                    this.getSelectedMarkers(shape)
                })
            }

            if(shape instanceof google.maps.Circle) {
                addListener(shape, 'center_changed', ()=> {
                    this.getSelectedMarkers(shape)
                    this.centerRadiusInfoBubble(shape)
                })

                // Resize for circle
                addListener(shape, 'radius_changed', ()=> {
                    this.getSelectedMarkers(shape)
                    this.showRadiusInfoBubble(shape)
                })

            }
        },
        getSelectedMarkers(shape: GoogleShapes){
            const geometry = google.maps.geometry
            this.markers.forEach((marker)=> {
                const latLng = new google.maps.LatLng(marker.location)

                if (shape instanceof google.maps.Polygon) {
                    marker.isSelected =
                        geometry.poly.containsLocation(
                            latLng,
                            shape
                        )
                } else if (shape instanceof google.maps.Circle) {
                    const distanceFromCenter = geometry.spherical.computeDistanceBetween(
                        latLng,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        shape.getCenter()!)

                    marker.isSelected = distanceFromCenter <= shape.getRadius()
                }
            })
        },
        generateMarkerIcon(gmapRecordIconFieldValue = 'Default', gmapRecordBackgroundFieldValue = 'Default') {
            if(!gmapRecordIconFieldValue) {
                gmapRecordIconFieldValue = 'Default'
            }

            if(!gmapRecordBackgroundFieldValue) {
                gmapRecordBackgroundFieldValue = 'Default'
            }
            const foundGmapIconSettingMetadata = this.gmapIconSetting.iconSettingMetadata[gmapRecordIconFieldValue] || this.gmapIconSetting.iconSettingMetadata['Default']
            const foundGmapBackgroundSettingMetadata = this.gmapIconSetting.backgroundSettingMetadata[gmapRecordBackgroundFieldValue] || this.gmapIconSetting.backgroundSettingMetadata['Default']
            if(!foundGmapIconSettingMetadata || !foundGmapBackgroundSettingMetadata) {
                return null
            }
            
            // Set Background Color
            const svgElement = new DOMParser().parseFromString(`<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 3.34a10 10 0 1 1 -4.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 4.995 -8.336z" stroke-width="0" fill="currentColor"></path>
            </svg>`, 'text/xml').firstChild as HTMLElement
            svgElement.style.color = foundGmapBackgroundSettingMetadata.backgroundColor

            return {
                label: {
                    fontFamily: 'tabler-icons',
                    color: foundGmapIconSettingMetadata.iconColor || '#FFF',
                    fontSize: '20px',
                    text:' ',
                    className: `ti ti-${foundGmapIconSettingMetadata.icon}`,
                
                },
                icon: {
                    url: `
                        data:image/svg+xml;utf-8,
                        ${svgElement.outerHTML}
                    `,
                    scaledSize: new google.maps.Size(50,50),
                }
            }

        },
        createMarker(geoCodedRecord: GmapRecord & GeoLatLng, disableAutoPan = false){
            const generatedIconDetails = this.generateMarkerIcon(geoCodedRecord.iconFieldValue, geoCodedRecord.backgroundFieldValue)
            const marker = new google.maps.Marker({
                position: this.convertoLatLng(geoCodedRecord),
                map: this.map,
                title: `Marker ${geoCodedRecord.id}`,
                draggable: false,
                optimized: true,
                ...(generatedIconDetails),
                clickable: this.integrateInfoWindows
            })
            
            if(this.integrateInfoWindows) { 
                const contentBody = geoCodedRecord.body.map(line => {
                    return `<strong>${line.label}</strong>: ${line.value}</br>`
                }).join('\n')

                const url = geoCodedRecord.url ? `<a href="${geoCodedRecord.url.value}">${geoCodedRecord.url.label}</a>` : ''

                const infoWindow = new google.maps.InfoWindow({
                    content: 
                    `<p style="font-size:16px">
                        <strong>${geoCodedRecord.header}</strong></br></br>
                        ${contentBody}
                        ${url}
                    </p>`,
                    disableAutoPan,
                })
                if(this.openInfoWindowsOnPin){ 
                    infoWindow.open(this.map, marker)
                }
                
    
    
                marker.addListener('click', () => {
                    infoWindow.open(this.map, marker)
                })

                this.oms?.addMarker(marker)
            }
            

            return marker
        },
        convertoLatLng(geoCodedRecord: GeoLatLng){
            return new google.maps.LatLng(parseFloat(geoCodedRecord.latitude), parseFloat(geoCodedRecord.longitude))
        },
        createDrawingManager(){
            const drawingManager = new google.maps.drawing.DrawingManager({ map: this.map })

            // add built in controls for polygon and circle
            drawingManager.setOptions({
                drawingControlOptions: {
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON,
                        google.maps.drawing.OverlayType.CIRCLE
                    ]
                },
                polygonOptions: { editable: true, draggable: true },
                circleOptions: { editable: true, draggable: true },
            })

            this.createCustomButtonGroup()

            // if drawing of shape is complete
            google.maps.event.addListener(drawingManager, 'overlaycomplete', (drawnShape: google.maps.drawing.OverlayCompleteEvent)=> {
                // only allow one shape at a time
                this.deleteAllShapes()
                // turn of drawing mode
                drawingManager.setDrawingMode(null)

                const shape = drawnShape.overlay
                this.shapes.push(shape)

                this.addShapeListeners(shape)
                this.getSelectedMarkers(shape)
                if (shape instanceof google.maps.Circle) {
                    this.showRadiusInfoBubble(shape)
                }
            })
        },
        isAddressGeocodable(address: string) {
            return address && address.length >= 14
        },
        shouldGeocodeLocation(status: string){
            if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT || status == google.maps.GeocoderStatus.REQUEST_DENIED
                || status == google.maps.GeocoderStatus.UNKNOWN_ERROR || status == google.maps.GeocoderStatus.ERROR || status == '') {
                return true
            } else {
                return false
            }
        },
        isElementHidden(elem: HTMLElement){
            return !( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        addMarkerToMap(geoCodedRecord: GmapRecord & StackGeoLocation){
            if(geoCodedRecord.status !== 'OK') return
            const geolocation = this.convertoLatLng(geoCodedRecord)
            if(this.panMapOnPin) {
                
                this.bounds.extend(geolocation)
                this.map?.fitBounds(this.bounds)
                this.map?.panToBounds(this.bounds)
            }
            this.markerClusterer?.addMarker(this.createMarker(geoCodedRecord))

            if(this.enableDrawingManager) {
                this.markers.push({
                    id: geoCodedRecord.id,
                    location: geolocation,
                    isSelected: false
                })
            }
            
        },
        clearMarkers() {
            this.oms?.clearMarkers()
            this.markerClusterer?.clearMarkers(false)
            this.markerClusterer?.onRemove()
            this.markerClusterer?.setMap(null)
            this.$emit('update:markers',[])
        },
        geocodeUnknownAddress() {
            setTimeout(() => {
                if (!window.multiAPIMapQueue || window.multiAPIMapQueue.isEmpty()) return
                const toBeGeocoded = window.multiAPIMapQueue.pop()
                if(!toBeGeocoded) return

                this.geocoder.geocode({ address: toBeGeocoded.address }, (results, status) => {
                    const geocodedLocation = { address: toBeGeocoded.address, status, latitude: '', longitude: '' }
                    if (status == google.maps.GeocoderStatus.OK && results) {
                        const geoLocation = results[0].geometry.location
                        geocodedLocation.latitude = geoLocation.lat().toString()
                        geocodedLocation.longitude = geoLocation.lng().toString()
                        this.addMarkerToMap({...toBeGeocoded, ...geocodedLocation})
                        this.geoCodeCount++
                    }

                    if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT && toBeGeocoded.attempts <= 10) {
                        window.multiAPIMapQueue.push(toBeGeocoded)
                        this.delay = this.delay + 20
                        toBeGeocoded.attempts++
                    }
                    try {
                        locationService.upsertLocations(geocodedLocation).then(() => {
                            this.geocodeUnknownAddress()
                        })
                    } catch(e) {
                        console.log(e)
                    }


                    if (this.debugGoogleMapsApi) {
                        console.log(`Coded Number : ${this.geoCodeCount} with a status of ${status}. Address: ${toBeGeocoded.address} At ${dayjs().format('h:mm:ss A')} `)
                        console.log(`Delay is now at ${this.delay}`)

                    }

                })

            }, this.delay)
        },
    },
    beforeDestroy() {
        this.clearMarkers()
    }
})